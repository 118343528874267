import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/employment-history",
  "pageType": "card",
  "title": "Employment History",
  "backgroundImage": "servers",
  "layoutClass": "employment-history"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`iWeb Solutions`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Operations / Security`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nov 2016 - Present`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lead Programmer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`July 2012 - Nov 2016`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Programmer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Feb 2010 - July 2012`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Digipigeon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Senior Developer & Project Manager`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jan 2009 - Feb 2010`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Developer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Aug 2008 - Jan 2009`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Keele University`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Computer Science teaching assistant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sept 2007 - July 2008`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Student's Union Steward Supervisor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`July 2006 - July 2007`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Student's Union Steward`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jan 2006 - July 2006`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Digipigeon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Freelance designer & programmer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nov 2005 - Aug 2008`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      